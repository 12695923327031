import React, { useContext, useEffect } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { filterProductsBy, filterProductsByAny, markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Modal from 'react-modal';
import "./new-prods.scss"
import "./bolds.scss"
import "./mounting-info.scss"
import Col3 from "../grid/Col3"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import Col2 from "../grid/Col2"
import FormInput from "../olc-framework/FormInput"
import FormSelect from "../olc-framework/FormSelect"
import { GetModal } from "../configurable/Modal"
import Expandable from "../configurable/Expandable"



const accessoriesInfo = ({ markdownNodes }) => {
  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);

  const [hasUsedFilters, setHasUsedFilters] = React.useState(false);




  return (
    <div >
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Our Products
        </Heading>
      </Hero>
      <Block >
        <Section>
          <div className="container container--column illustrated-house-block">
            <Heading level={2} >Solar PV Accessories</Heading>
            <p className="bold-formatting">
            To make the most of your solar PV system, we offer several additional items which; can be added to the system <strong>to increase its effectiveness</strong>, however these are not always necessary but rather optional. Below is an overview of the additional items available.
            </p>
            
            <p>Just click to jump to the corresponding section</p>
            <div className="mounting-ills row" >
              <div className="mounting-ills__item">
                <img src={require('../../images/accessories/optimiser.png')} alt="my image"  />
                <BlockCTA className="blue-btn" url="/accessories-info#optimiser">
                  Optimiser
                </BlockCTA>
              </div>
              <div className="mounting-ills__item">
                <img src={require('../../images/accessories/bird.png')} alt="my image"  />
                <BlockCTA className="blue-btn" url="/accessories-info#bird-netting">
                Bird Netting
                </BlockCTA>
              </div>
              <div className="mounting-ills__item">
                <img src={require('../../images/accessories/ev.png')} alt="my image"  />
                <BlockCTA className="blue-btn" url="/accessories-info#ev">
                EV Chargers
                </BlockCTA>
              </div>
              <div className="mounting-ills__item">
                <img src={require('../../images/accessories/other.png')} alt="my image"  />
                <BlockCTA className="blue-btn" url="/accessories-info#other-products">
                Other Products
                </BlockCTA>
              </div>
            </div>
          </div>

        </Section>

        <div  id="optimiser">
          <Section>
            <div className="row" >
              <Col12>
                <Heading level={2}  >Optimiser </Heading>
                <p className="bold-formatting">
                An optimiser is used for solar PV systems where <strong>shading is a factor</strong>; an optimiser is a DC/DC converter that is connected to each solar panel. For large commercial systems, optimisers are available, which can be connected to two panels reducing cost. The optimiser monitors the performance of each solar panel and enables the optimal power output to be drawn from each solar panel.
                </p>
              </Col12>
            </div>
            <div className="row reverse-cols" style={{ marginTop: 40 }}>
              <Col3 className="left_bottom" style={{ marginTop: 40 }}>
                <img src={require('../../images/accessories/solaredge.png')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={3} underlined >SolarEdge Optimiser </Heading>
                <p>
                The SolarEdge Power Optimisers increase energy output from PV systems by constantly tracking the maximum power point (MPPT) of each module individually
                </p>
                <TickList nolines greenticks>
                  <li>Furthermore, the Power Optimisers monitor the performance of each module and communicate performance data to the SolarEdge monitoring platform for enhanced, cost-effective module-level maintenance. </li>
                </TickList>
                <BlockCTA secondary url={`/static/df0417b8100ae5dd5e502267d4fdb6e9/get-solar-edge-power-optimizer.pdf`} right arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols" >
              <Col9 >
                <Heading level={3} underlined >Huawei Optimiser  </Heading>
                <p className="bold-formatting">
                The Huawei Smart SUN2000-450W-P Optimizer is designed to extract the <strong>maximum power rating</strong> of up to 450w. In this way, the component can once again noticeably increase the efficiency of the system. 
                </p>
                <TickList nolines greenticks>
                  <li>To make this happen, advanced technology is integrated by the manufacturer.   </li>
                </TickList>
                <BlockCTA external  secondary url={`https://get-uk.com/static/5272bb496ba09185be3c38eb96b2b175/huawei.pdf`}  arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
              <Col3 >
                <img src={require('../../images/accessories/huawei_op.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols" >
              <Col3 className="left_bottom" >
                <img src={require('../../images/accessories/tigo.png')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={3} underlined >Tigo TS4 Optimiser </Heading>
                <p className="bold-formatting">
                The TS4-A-O (Optimization) is the advanced add-on optimization solution that brings smart module functionality to standard PV modules for higher reliability. Improve energy efficiency by upgrading underperforming PV systems or adding smart features to new installations. 
                </p>
                <TickList nolines greenticks>
                  <li>Complies with 2017 and 2020 NEC rapid shutdown requirements. The TS4-A-O add-on supports PV modules up to 700W.

                  </li>
                </TickList>
                <BlockCTA external secondary url={`https://get-uk-docs.netlify.app/tigo.pdf`} right arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
            </div>
          </Section>
          <div className="divider" />
        </div>
        <div  id="bird-netting">
          <Section>
            <div className="row" >
              <Col12>
                <Heading level={2}  >Bird Netting </Heading>
                <p>
                Typically supplied for domestic installations for pitched roofs to present birds from nesting underneath the panels. 
                </p>
              </Col12>
            </div>
            <div className="row reverse-cols" style={{ marginTop: 40 }}>
              <Col3 className="left_bottom" >
                <img style={{ marginTop: 20 }} src={require('../../images/accessories/enviroguard.jpg')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={3} underlined >EnviroGuard Bird Netting </Heading>
                <p className="bold-formatting">
                EnviroGuard’s Solar Panel Bird Exclusion System is used <strong>worldwide</strong> to deter feral pigeons and larger birds from roosting and nesting in the void area between PV solar panels and pitched roofs. 
                </p>
                <TickList nolines greenticks>
                  <li>The system features a unique, patented solar exclusion mesh clip manufactured from ultra-violet stabilised plastic and high quality 12.5mm X 25mm galvanised steel mesh.  </li>
                </TickList>
                <BlockCTA secondary url={`/static/9c60ad99c5065597e08ca21d651f72e2/get-enviroguard-solar-panel-bird-exclusion-system-product-spec-sheet.pdf`} right arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
            </div>
          </Section>
          <div className="divider" />
        </div>
        <div id="ev" >
          <Section>
            <div className="row" >
              <Col12>
                <Heading level={2}  >EV Chargers </Heading>
                <p className="bold-formatting">
                Green Energy Together work with some of the world’s leading manufacturers to provide our customers with the right solution for them. Typically in households, an<strong> AC charger / Wallboxes</strong> are used, as these electric car chargers have power outputs of 3.7kW or 7kW. In a domestic setting, a 7kW EV charger takes 8-10 hours to recharge from <strong>empty to full</strong>. Whereas, In a Commercial setting, the chargers use a 3-phase DC power supply, with a 22kW electric car charger coming with quicker charge times.
                </p>
              </Col12>
            </div>
            <div className="row reverse-cols" style={{ marginTop: 40 }}>
              <Col3 className="left_bottom" >
                <img style={{ marginTop: 40 }} src={require('../../images/accessories/evie.png')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={3} underlined >Evie Smart Car Charger </Heading>
                <p className="bold-formatting">
                The EVIE Smart Home Charge point has been designed to provide the user with the perfect EV charging solution for the <strong>home & commercial space</strong>. All users can control EVIE using their mobile phones, allowing them to monitor all their charging activity. 
                </p>
                <TickList nolines greenticks>
                  <li>An LCD touchscreen is also included to operate the charger manually with RFID cards.</li>
                </TickList>
                <BlockCTA secondary url={`/static/b68e86b0722e23d83004f2401b47292a/get-evie-flyer-7.2kw-smart-home.pdf`} right arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols" >
              <Col9 >
                <Heading level={3} underlined >Elvi Car Charger </Heading>
                <p className="bold-formatting">
                High performance, low maintenance, and future-proof, EVBox Elvi offers an <strong>effortless</strong> home charging experience. Its modular design and simple interface make electric vehicle charging and installation easier than ever before.
                </p>
                <TickList nolines greenticks>
                  <li>Intelligent and connected, Elvi provides you with real-time charging session insights plus remote control and monitoring.</li>
                </TickList>
                <BlockCTA secondary url={`/static/e353982edb0fac21ce4173a4976a5839/elvi-car-charger-evbox.pdf`} arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
              <Col3 >
                <img src={require('../../images/accessories/elvi.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols" >
              <Col3 className="left_bottom" >
                <img src={require('../../images/accessories/podpoint.png')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={3} underlined >Pod Point Solo EV Charger </Heading>
                <p className="bold-formatting">
                Pod Point Solo EV Charger - Pod Point S7-2C-6MA-2 Solo, single-phase electric vehicle chargers provide state-of-the-art EV charging for a range of leading electric car manufacturers. <strong>Adaptable mounting options</strong>, Pod Point’s home socketed chargers gives the user flexibility on where to mount the device, but also the position of the charger as well. 
                </p>
                <TickList nolines greenticks>
                  <li>App-controlled, Pod Point charging stations have a built-in WIFI function that connects to the cloud and acts as your dedicated account manager. 

                  </li>
                </TickList>
                <BlockCTA secondary url={`/static/45bc1d81f28a2d3f3ff740b995aafa58/pod-point-solo-car-charger-single-phase-pod-point.pdf`} right arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
            </div>
          </Section>
        </div>
        <div className="divider" />
        <div id="other-products"  >
          <Section>
            <div className="row" >
              <Col12>
                <Heading level={2}  >Other Products </Heading>

              </Col12>
            </div>
            <div className="row reverse-cols" style={{ marginTop: 40 }}>
              <Col3 className="left_bottom" >
                <img style={{ marginTop: 40 }} src={require('../../images/accessories/wifi-booster.jpg')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={3} underlined >BrosTrend AC1200 WiFi Extender - E1</Heading>
                <p className="bold-formatting">
                Boosts your WiFi Range and Connects up to 20 Devices by Using this WiFi Booster. With a simultaneous speed of 867Mbps on 5GHz WiFi Band and 300Mbps on 2.4GHz Band, Enjoy Online 4K Video Streaming, Multi-users Gaming, Music etc With this WiFi Extender Without Buffering or Interruption
                </p>
                <TickList nolines greenticks>
                  <li>Extended wireless coverage</li>
                </TickList>
                <BlockCTA secondary url={`/static/7635eb0b2862fa9ed751943f50d83e9b/get-brostrend_wi-fi_range_extender_signal_booster_manual_e1_v1_v2.pdf`} right arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols" >
              <Col9 >
                <Heading level={3} underlined >immerSUN - Model T1060 - Automatic Power Controller</Heading>
                <p className="bold-formatting">
                The immerSUN is our ‘state of the art’ energy-saving device. It helps you to self-consume the green energy produced by your microgeneration system. The device is essentially an automatic power controller that diverts surplus power to a designated load, normally a hot water heater, to save energy and minimise your utility bills. Compatible with all inverters, smart meters and energy monitoring systems, the immerSUN boasts an extensive range of additional features. This controller really is the perfect addition to any PV or wind turbine installation.
                </p>
                <TickList nolines greenticks>
                  <li>Tracks surplus power and diverts this energy to the load</li>
                </TickList>
                <BlockCTA secondary  url={`/static/843ac86f1acb5d47da2956ebaf19682c/get-immersun-automatic-power-controller.pdf`} arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
              <Col3 >
                <img src={require('../../images/accessories/immersun.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols" >
              <Col3 className="left_bottom" >
                <img src={require('../../images/accessories/iboost.jpg')} alt="" />
              </Col3>
              <Col9 className="right_top" >
                <Heading level={3} underlined >Solar iBoost+ </Heading>
                <p className="bold-formatting">
                Solar iBoost+ features connections for 2 immersions, switching between them automatically to maximise electric water heating systems. It has a wireless sender which eliminates unsightly wiring. Solar iBoost+ displays real-time information such as "Heating by Solar 1.6kW" and historical energy-saving figures are seen at the push of a button.
                </p>
                <TickList nolines greenticks>
                  <li>2 year warranty & CE compliance to all product and safety standards conducted by independent test laboratories. 

                  </li>
                </TickList>
                <BlockCTA external secondary  right url={`https://get-uk-docs.netlify.app/iboost.pdf`} arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
            </div>
          </Section>
          <Section>
            <div className="row reverse-cols" >
              <Col9 >
                <Heading level={3} underlined >SolarEdge Fire Gateway </Heading>
                <p className="bold-formatting">
                The firefighter gateway provides centralized safety management of SolarEdge systems. Firefighters can immediately stop the production of a SolarEdge PV system, either manually through an emergency stop button or automatically through a fire alarm control panel system. This reduces string voltage to a safe voltage using the unique SafeDC™ feature.
                </p>
                <TickList nolines greenticks>
                  <li>Real-time indication of system DC voltage for safety assurance.</li>
                </TickList>
                <BlockCTA external secondary url={`https://get-uk-docs.netlify.app/solaredge_gw.pdf`} arrow="right">
                  View Product
                </BlockCTA>
              </Col9>
              <Col3 >
                <img src={require('../../images/accessories/fire_gateway.jpg')} alt="" />
              </Col3>
            </div>
          </Section>
        </div>
      </Block>
    </div>
  )
}

export default accessoriesInfo
